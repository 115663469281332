<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.consumerUser.title") }}</h3>
    <consumer-filters class="mb-5" />
    <user-table
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />
    <blocked-modal />
  </div>
</template>

<script>
import userMixin from "./../mixins/userMixin";

import ConsumerFilters from "./../components/Consumer/ConsumerFilters";
import UserTable from "./../components/User/UserTable";
import BlockedModal from "./../components/Base/BaseBlockedModal";

export default {
  components: {
    ConsumerFilters,
    UserTable,
    BlockedModal
  },
  mixins: [userMixin],
  data() {
    return {
      fields: [
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "createdAt", label: "createdat", sort: true },
        { key: "registrationDate", label: "registrationDate", sort: true },
        { key: "blockedBy", label: "blocked", sort: false },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.gotoUserDetails
        },
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openDeleteConfirmationModal
        },
        {
          label: "button.suspend",
          variant: "secondary",
          hidden: user => user.status === "active",
          callbackFunc: this.suspendUser
        },
        {
          label: "button.reactivate",
          variant: "orange",
          hidden: user => user.status === "suspended",
          callbackFunc: this.reactivateUser
        }
      ]
    };
  },
  computed: {
    consumers() {
      return this.$store.getters["consumer/getConsumerUsers"];
    },
    formatedUsers() {
      return this.consumers.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          email: item.email,
          status: item.status,
          blockedBy: item.blockedBy,
          createdAt: item.createdAt,
          registrationDate: item.registrationDate
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["consumer/getSortBy"];
      },
      set(value) {
        this.$store.commit("consumer/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["consumer/getSortDesc"];
      },
      set(value) {
        this.$store.commit("consumer/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    async gotoUserDetails(userId) {
      const selectedUser = this.consumers.find(u => u._id === userId);
      this.$store.commit("consumer/SET_CURRENT_CONSUMER_USER", selectedUser);
      this.$router.push({ name: "ConsumerDetails", params: { id: userId } });
    },
    openDeleteConfirmationModal(userId) {
      const user = this.consumers.find(u => u._id === userId);
      if (user.profile)
        var userName = `${user.profile?.name} ${user.profile?.surname}`;
      else userName = "";
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "confirm.removeUserTitle",
        messageText: "confirm.removeUserMessage",
        messageParams: { userName },
        confirmBtnCallback: this.deleteConsumerUser,
        confirmBtnParams: { userId: user._id },
        type: "DELETE"
      });
    },
    async deleteConsumerUser({ userId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("consumer/deleteConsumerUser", userId);

        await this.$store.dispatch("consumer/retrieveConsumerUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async suspendUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/suspendUser", userId);
        await this.$store.dispatch("consumer/retrieveConsumerUsers");
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async reactivateUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/reactivateUser", userId);
        await this.$store.dispatch("consumer/retrieveConsumerUsers");
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
